import purinaLogo from '../../assets/images/purina-logo.png';
import SocialMediaIconFb from './facebook-ic.png';
import SocialMediaIconTw from './twitter-ic.png';
import SocialMediaIconIn from './instagram-ic.png';
import SocialMediaIconYt from './youtube-ic.png';
import SocialMediaIconFbInv from './facebook-ic-inv.png';
import SocialMediaIconTwInv from './twitter-ic-inv.png';
import SocialMediaIconInInv from './instagram-ic-inv.png';
import SocialMediaIconYtInv from './youtube-ic-inv.png';
import PrivacyOptionsIcon from './privacy-options-ic.png';
import { useCookies } from 'react-cookie';
import { trackClickThrough } from '../../utils/helpers';

import classes from './Footer.module.scss'
import windowDimensions from 'react-window-dimensions';

const Footer = ({width, height}) => {
    const [cookies, setCookie] = useCookies(['A8DUUID']);

    const socialMediaIcons = <div className={classes.SocialMediaIcons}>
        <a href="https://www.facebook.com/purina" onClick={(event) => {trackClickThrough(cookies.A8DUUID, event, 'footer', 'https://www.facebook.com/purina')}}><img src={SocialMediaIconFb} alt={'Facebook Social media icon'}/></a>
        <a href="https://twitter.com/purina" onClick={(event) => {trackClickThrough(cookies.A8DUUID, event, 'footer', 'https://twitter.com/purina')}}><img src={SocialMediaIconTw} alt={'Twitter Social media icon'}/></a>
        <a href="https://www.youtube.com/user/ThePurinaNetwork" onClick={(event) => {trackClickThrough(cookies.A8DUUID, event, 'footer', 'https://www.youtube.com/user/ThePurinaNetwork')}}><img  src={SocialMediaIconYt} alt={'Youtube Social media icon'}/></a>
        <a href="https://www.instagram.com/purina" onClick={(event) => {trackClickThrough(cookies.A8DUUID, event, 'footer', 'https://www.instagram.com/purina')}}><img src={SocialMediaIconIn} alt={'Instagram Social media icon'}/></a>
    </div>

    const privacyButtonInlineStyle = (width <= 960) ? {border: "none", color: "white", padding: "5px 0px 5px 0px", fontSize: "16px"} : {border: "none", color: "white", padding: "3px 0px 5px 0px", fontSize: "10px"}

    const container = <div className={classes.FooterContainer}>
            <div className={classes.TopFooterText}>
                <a href="https://www.purina.com/contact-us">CONTACT US</a>
                <a href="https://www.purina.com/international-sites">INTERNATIONAL SITES </a>
                <a href="https://www.purina.com/sitemap">SITE MAP</a>
            </div>
            {socialMediaIcons}
            <div className={classes.BottomFooterText}>            
                <a href="https://www.purina.com/terms-and-conditions">TERMS & CONDITIONS</a>
                <a href="https://www.purina.com/privacy-policy#notice-at-collection">NOTICE AT COLLECTION</a>
                <a href="https://www.purina.com/privacy-policy">PRIVACY POLICY (UPDATED)</a>
                {/* <a href="https://privacyportal.onetrust.com/webform/242e27bf-9452-4c8d-9f7b-5ebde7934198/0d008ad1-f4a2-4db8-8a6e-81f37263cc1d">YOUR PRIVACY CHOICES <img className={classes.PrivacyOptionsIcon} src={PrivacyOptionsIcon} alt="California Consumer Privacy Act (CCPA) Opt-Out Icon"/></a> */}
                <a id="ot-sdk-btn" alt="California Consumer Privacy Act (CCPA) Opt-Out Icon" class="ot-sdk-show-settings" onMouseOver={(event) => event.target.style.backgroundColor='#2B2B2B'} style={privacyButtonInlineStyle}>YOUR PRIVACY CHOICES <img className={classes.PrivacyOptionsIcon} src={PrivacyOptionsIcon} alt="California Consumer Privacy Act (CCPA) Opt-Out Icon"/></a>
                <a href="https://www.purina.com/cookie-policy">COOKIE POLICY</a>
                <a href="https://www.purina.com/accessibility">ACCESSIBILITY</a>
                <a href="https://www.purina.com/terms-of-sale">TERMS OF SALE</a>
                <a href="https://www.purina.com/linking-policy">LINKING POLICY</a>                
                <a href="https://www.purina.com/copyright-infringement-notification">COPYRIGHT INFRINGEMENT NOTIFICATION</a>                
            </div>
            <div className={classes.BottomFooterText} style={{marginTop: width <= 960 ? "0px" : "10px"}}>                                        
                <a href="https://www.purina.com/user-generated-content">USER GENERATED CONTENT</a>
                <a href="https://www.purina.com/about/supply-chains-act">SUPPLY CHAINS ACT</a>
            </div>
    </div>


    return container; 
}

export default windowDimensions()(Footer);