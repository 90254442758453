import headerImg from './Header.png'
import dogImgSm from './Dog_Placeholder_sm.png'
import dogImgLg from './Dog_Placeholder_lg.png'
import ttdLogo from './TTD_Logo.png';
import milesWithMaple from './mileswithmaple-2023-winner.png';
import classes from './Header.module.scss';
import windowDimensions from 'react-window-dimensions';
import headerPhase1 from './purina-thanksgivingstopdog-2024-phase1.png';
import headerPhase2 from './purina-thanksgivingstopdog-2024-phase2.png';
import headerPhase3 from './purina-thanksgivingstopdog-2024-phase3.png';
import { trackClickThrough } from '../../utils/helpers';
import { useCookies} from 'react-cookie';

const Header = ({width, height}) => {
    const [cookies, setCookies] = useCookies(['A8UUID']);

    const phaseOne = <div style={{}}>
        <div style={{backgroundColor : width > 760 ? "#ED1C24" : "white"}}>
            {width > 760 && <img src={headerPhase1} style={{width: "100%"}} alt="Thanksgiving's Top Dog Contest - Header"/>}
            {width <= 760 && <img src={dogImgSm} alt="Thanksgiving's Top Dog Contest - Header" style={{width: "100%"}}/>}
        </div>

        {width <= 760 && 
            <div className={classes.highlight}>
                <a href="https://www.instagram.com/mileswithmaple?igsh=MzRlODBiNWFlZA==" className={classes.hashTag} onClick={(event) => trackClickThrough(cookies.A8UUID, event, 'header', 'https://www.instagram.com/mileswithmaple?igsh=MzRlODBiNWFlZA==')}>@MILESWITHMAPLE | 2023 WINNER</a> 
                <img src={ttdLogo} className={classes.ttdLogo} alt="TTD logo"/>                    
                <h2 className={classes.highlightTitle}>
                    Thanksgiving’s Top Dog Contest
                </h2>
                <p className={classes.highlightDescription}>After last year’s winner, @MileswithMaple stole the show, Thanksgiving’s Top Dog is back! For the second year in a row, you can submit a photo or video of your dog for the opportunity to be named Thanksgiving’s Top Dog and for them to be featured during the National Dog Show on NBC on Thanksgiving Day! </p>
            </div>        
        }
    </div>

    const phaseTwo = <div style={{}}>
        <div style={{backgroundColor : width > 760 ? "#ED1C24" : "white"}}>
            {width > 760 && <img src={headerPhase2} style={{width: "100%"}} alt="Thanksgiving's Top Dog Contest - Header"/>}
            {width <= 760 && <img src={dogImgSm} alt="Thanksgiving's Top Dog Contest - Header" style={{width: "100%"}}/>}
        </div>

        {width <= 760 && 
            <div className={classes.highlight}>
                <a href="https://www.instagram.com/mileswithmaple?igsh=MzRlODBiNWFlZA==" className={classes.hashTag} onClick={(event) => trackClickThrough(cookies.A8UUID, event, 'header', 'https://www.instagram.com/mileswithmaple?igsh=MzRlODBiNWFlZA==')}>@MILESWITHMAPLE | 2023 WINNER</a> 
                <img src={ttdLogo} className={classes.ttdLogo} alt="TTD logo"/>                    
                <h2 className={classes.highlightTitle}>
                    Thanksgiving’s Top Dog Contest
                </h2>
                <p className={classes.highlightDescription}>Submissions for this year’s Thanksgiving’s Top Dog Contest are now CLOSED! But for just the second time ever, you can vote for Thanksgiving’s Top Dog! Come back on November 8 to check out the finalists and again on Thanksgiving Day to cast your vote! </p>
            </div>        
        }
    </div>

    const phaseThree = <div style={{}}>
        <div style={{backgroundColor : width > 760 ? "#ED1C24" : "white"}}>
            {width > 760 && <img src={headerPhase3} style={{width: "100%"}} alt="Thanksgiving's Top Dog Contest - Header"/>}
            {width <= 760 && <img src={dogImgSm} alt="Thanksgiving's Top Dog Contest - Header" style={{width: "100%"}}/>}
        </div>

        {width <= 760 && 
            <div className={classes.highlight}>
                <a href="https://www.instagram.com/mileswithmaple?igsh=MzRlODBiNWFlZA==" className={classes.hashTag} onClick={(event) => trackClickThrough(cookies.A8UUID, event, 'header', 'https://www.instagram.com/mileswithmaple?igsh=MzRlODBiNWFlZA==')}>@MILESWITHMAPLE | 2023 WINNER</a> 
                <img src={ttdLogo} className={classes.ttdLogo} alt="TTD logo"/>                    
                <h2 className={classes.highlightTitle}>
                    Vote for Thanksgiving’s Top Dog
                </h2>
                <p className={classes.highlightDescription}>For just the second time in the history of the National Dog Show, you can vote for Thanksgiving’s Top Dog!</p>
                <p className={classes.highlightDescription}>No purchase necessary. Ends 11-28-24. For Official Rules, visit ThanksgivingsTopDog.com </p>
            </div>        
        }
    </div>

    const phaseFour = <div style={{}}>
        <div style={{backgroundColor : "white"}}>
            {width > 760 && <img src={headerPhase3} style={{width: "100%"}} alt="Thanksgiving's Top Dog Contest - Header"/>}
            {width <= 760 && <img src={dogImgSm} alt="Thanksgiving's Top Dog Contest - Header" style={{width: "100%"}}/>}
        </div>

        {width <= 760 && 
            <div className={classes.highlight}>
                <a href="https://www.instagram.com/mileswithmaple?igsh=MzRlODBiNWFlZA==" className={classes.hashTag} onClick={(event) => trackClickThrough(cookies.A8UUID, event, 'header', 'https://www.instagram.com/mileswithmaple?igsh=MzRlODBiNWFlZA==')}>@MILESWITHMAPLE | 2023 WINNER</a> 
                <img src={ttdLogo} className={classes.ttdLogo} alt="TTD logo"/>                    
                <h2 className={classes.highlightTitle}>
                    Vote for Thanksgiving’s Top Dog
                </h2>
                <p className={classes.highlightDescription}>For just the second time in the history of the National Dog Show, you can vote for Thanksgiving’s Top Dog!</p>
                <p className={classes.highlightDescription}>No purchase necessary. Ends 11-28-24. For Official Rules, visit ThanksgivingsTopDog.com </p>
            </div>        
        }
        </div>

    const searchParams = new URLSearchParams(window.location.search);

    const now = new Date();
    const phaseTwoStartDate = new Date('2024-11-02T00:00:00');
    const phaseTwoThreetDate = new Date('2024-11-09T00:00:00');
    const phaseTwoFourDate = new Date('2024-11-28T00:00:00');
    
    let phase = phaseOne;
    if((searchParams.has('phase') && parseInt(searchParams.get('phase')) === 2) || now >= phaseTwoStartDate) { phase = phaseTwo;}     
    if((searchParams.has('phase') && parseInt(searchParams.get('phase')) === 3) || now >= phaseTwoThreetDate) { phase = phaseThree;} 
    if((searchParams.has('phase') && parseInt(searchParams.get('phase')) === 4) || now >= phaseTwoFourDate) { phase = phaseFour;}

    return phase;
}

export default windowDimensions()(Header);
