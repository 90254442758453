import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_HOST
axios.defaults.headers.common = {
  'Content-Type': 'application/json',
  // 'Accept': 'application/json',
  // 'Access-Control-Allow-Origin': '*',
  // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  'x-api-key' : process.env.REACT_APP_API_KEY,
  'x-api-version' : process.env.REACT_APP_API_VERSION
}

export default axios;