
import { useState } from 'react';
import VideoPlaceHolderImg from './Video_Placeholder.png';
import DogGrapficImg from './Dog_Graphic.png';
import InstagramTikTikGraphicImg from './Instagram_TikTok_Graphic.png';
import FiveFinalistsGraphicImg from './5_Finalists_Graphic.png';
import NDSGraphicImg from './NDS_Graphic.png';
import classes from './Instructions.module.scss';
import videoPoster from './thanksgiving-top-dog-frame.jpeg';
import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { useCookies } from 'react-cookie';
import { postEvent } from '../../utils/helpers';
import windowDimensions from 'react-window-dimensions';
import tempVideoStill from './phase3-temp-video-still.png';

const Instructions = ({width, height}) => {
    const [cookies, setCookies] = useCookies(['A8DUUID']); 
    const [initialVideoPlay, setInitialVideoPlay] = useState(true);

    const phaseOne = <div style={{textAlign: "center", marginBottom: "50px", paddingTop: "50px"}}>
        {/* <Moment>{dateToFormat}</Moment> */}
        <h2>Thanksgiving's Top Dog Contest Details</h2>
        <div>
            {/* <img src={VideoPlaceHolderImg} alt="Video placeholder" style={{width: "80%", marginLeft: "auto", marginRight: "auto"}}/> */}
            <video controls style={{width: "80%"}} poster={videoPoster} onPlay={(event) => {if(initialVideoPlay) {
                postEvent(cookies.A8DUUID, {
                    category: "user_actions",
                    subcategory : "home_page",
                    action : "video_play",
                    label : "thanksgiving_top_dog_contest",
                    client_id : process.env.REACT_APP_CLIENT_ID,
                    campaign_id : process.env.REACT_APP_CAMPAIGN_ID
                });
                setInitialVideoPlay(false);            
            }}}> 
                <source src="https://cdn.actv8me.com/da7d6760-646c-11ed-895b-37f2d48aaa22/assets/2024/10/17283362950.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </div>            
        <div className={classes.instructionTiles} style={{justifyContent: "center", marginTop: "70px", lineHeight: "28px", fontSize: "18px", gap: "50px 50px"}}>
            <div>
                <img src={DogGrapficImg} alt='Dog Graphic'/>
                <p style={{ }}>Take a photo or video of your dog (15 seconds max) that captures their unique personality or exhibits Top Dog-worthy traits.</p>
            </div>
            <div>
                <img src={InstagramTikTikGraphicImg} alt='Dog Graphic'/>
                <p style={{ }}>Share your photo or video on <br/>TikTok or Instagram using #ThanksgivingsTopDogContest by November 1, 2024.</p>
            </div>
            <div>
                <img src={FiveFinalistsGraphicImg} alt='Dog Graphic'/>
                <p style={{ }}>A panel of judges will choose 5 finalists to be featured on the National Dog Show on NBC <br/> where 1 grand prize winner will be chosen by you!</p>
            </div>
            <div>
                <img src={NDSGraphicImg} alt='Dog Graphic'/>
                <p style={{ }}>Tune into the National Dog Show on NBC Thanksgiving Day to see the finalists and vote for your favorite!</p>
            </div>
        </div>
    </div>

    const phaseTwo = <div style={{textAlign: "center", marginBottom: "50px", paddingTop: "50px"}}>
        <h2>How to Vote for Thanksgiving's Top Dog During the National Dog Show</h2>
        <div>
            <video controls style={{width: "80%"}} poster={videoPoster} onPlay={(event) => {if(initialVideoPlay) {
                postEvent(cookies.A8DUUID, {
                    category: "user_actions",
                    subcategory : "home_page",
                    action : "video_play",
                    label : "thanksgiving_top_dog_contest",
                    client_id : process.env.REACT_APP_CLIENT_ID,
                    campaign_id : process.env.REACT_APP_CAMPAIGN_ID
                });
                setInitialVideoPlay(false);            
            }}}> 
                <source src="https://cdn.actv8me.com/da7d6760-646c-11ed-895b-37f2d48aaa22/assets/2024/10/17297935290.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
            </video>            
        </div>
    </div>

    let phaseThree;

    if(width >= 1100) {
        phaseThree = <div style={{textAlign: "left", marginBottom: "50px", display: "flex", backgroundColor: "#EDF0F880", paddingTop: "100px", paddingBottom: "100px", fontFamily: "Inter"}}>
            <div style={{width: "30%", paddingLeft: "10%"}}>

            
            <h2 style={{fontSize: "40px", lineHeight: "48.5px"}}>How to Vote for Thanksgiving's Top Dog</h2>
            <p style={{lineHeight: "34px", fontSize: "22px", fontWeight: "300"}}>It was difficult, but a panel of judges narrowed down submissions to five finalists! Scroll down to learn a bit more about each finalist and come back Thanksgiving Day to cast your vote during the National Dog Show.</p>
            </div>            
            <div style={{width: "60%", textAlign: "center", verticalAlign: "middle"}}>
                <video controls style={{width: "80%", marginLeft: "auto", marginRight: "auto", position: "relative", top: "50%", transform: "translateY(-50%)"}} poster={videoPoster} onPlay={(event) => {if(initialVideoPlay) {
                    postEvent(cookies.A8DUUID, {
                        category: "user_actions",
                        subcategory : "home_page",
                        action : "video_play",
                        label : "thanksgiving_top_dog_contest",
                        client_id : process.env.REACT_APP_CLIENT_ID,
                        campaign_id : process.env.REACT_APP_CAMPAIGN_ID
                    });
                    setInitialVideoPlay(false);            
                }}}> 
                    <source src="https://cdn.actv8me.com/da7d6760-646c-11ed-895b-37f2d48aaa22/assets/2024/10/17297935290.mp4" type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                {/* <img src={tempVideoStill} alt="Video placeholder" style={{width: "80%", marginLeft: "auto", marginRight: "auto", position: "relative", top: "50%", transform: "translateY(-50%)"}}/> */}
            </div>
        </div>
    } else {
        phaseThree = <div style={{textAlign: "left", marginBottom: "20px", padding: "20px", backgroundColor: "#EDF0F880"}}>
            <div>
                <video controls style={{width: "100%"}} poster={videoPoster} onPlay={(event) => {if(initialVideoPlay) {
                    postEvent(cookies.A8DUUID, {
                        category: "user_actions",
                        subcategory : "home_page",
                        action : "video_play",
                        label : "thanksgiving_top_dog_contest",
                        client_id : process.env.REACT_APP_CLIENT_ID,
                        campaign_id : process.env.REACT_APP_CAMPAIGN_ID
                    });
                    setInitialVideoPlay(false);            
                }}}> 
                    <source src="https://cdn.actv8me.com/da7d6760-646c-11ed-895b-37f2d48aaa22/assets/2024/10/17297935290.mp4" type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>

            </div>            
            <h2 style={{fontSize: "26px", lineHeight: "39px", fontFamily: "Inter", fontWeight: "400"}}>How to Vote for Thanksgiving's Top Dog</h2>
            <p style={{lineHeight: "25.5px", fontSize: "14px", fontFamily: "Inter", fontWeight: "300"}}>It was difficult, but a panel of judges narrowed down submissions to five finalists! Scroll down to learn a bit more about each finalist and come back Thanksgiving Day to cast your vote during the National Dog Show.</p>

        </div>        
    }

    const searchParams = new URLSearchParams(window.location.search);

    const now = new Date();
    const phaseTwoStartDate = new Date('2024-11-02T00:00:00');
    const phaseTwoThreetDate = new Date('2024-11-09T00:00:00');
    
    let phase = phaseOne;
    if((searchParams.has('phase') && parseInt(searchParams.get('phase')) === 2) || now >= phaseTwoStartDate) { phase = phaseTwo;}     
    if((searchParams.has('phase') && parseInt(searchParams.get('phase')) === 3) || now >= phaseTwoThreetDate) { phase = phaseThree;} 

    return phase;
} 

export default windowDimensions()(Instructions);