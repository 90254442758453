
import PLPHomeStatueImg from './PLP_Home_Statue.png';
import windowDimensions from 'react-window-dimensions';
import classes from './MyPurina.module.scss';
import { postEvent } from '../../utils/helpers';
import { useCookies } from 'react-cookie';

const MyPurina = ({width, height}) => {
    const [cookies, setCookies] = useCookies(['A8DUUID'])

    const downloadUrl = 'https://mypurina.onelink.me/z05v/hzbojrvs';

    function trackDownload(event) {
        event.preventDefault();
        const url = downloadUrl;
        const payload = {
            category : "user_actions",
            subcategory : 'myPurina',
            action : "click_through",
            label : url,
            client_id : process.env.REACT_APP_CLIENT_ID,
            campaign_id : process.env.REACT_APP_CAMPAIGN_ID
        }
        postEvent(cookies.A8DUUID, payload, null, {url : url, target : '_self'})
    }

    return (
        <div style={{fontFamily: "Inter", marginBottom: "40px"}}>
        {width >= 960 && 
            <div style={{display: "flex"}}>            
                
                <div style={{width: "45%", paddingLeft: "5%", paddingTop: "6%"}}>
                    <h3 style={{fontSize: "40px", lineHeight: "49px", fontWeight: "400"}}>Earn 10 Points for Every $1 You Spend on Purchases with myPurina!</h3>
                    <p style={{fontSize: "22px", lineHeight: "34px", fontWeight: "300", marginBottom: "30px"}}>Download the myPurina app to earn exclusive rewards, FREE samples and more! Sign up now and create a pet profile to earn 400 points!</p>
                    {/* <button style={{width: "148px", height: "54px", backgroundColor: "#2B2B2B", color: "white", borderRadius: "100px", padding: "16px, 32px, 16px, 32px", fontSize: "18px"}}>Download</button> */}
                    <a href={downloadUrl} className={classes.DownloadButton} onClick={(event) => trackDownload(event)}>Download</a>
                </div>
                <div style={{width: "50%", padding: "5%", paddingTop: "100px"}}>
                    <img src={PLPHomeStatueImg} alt="myPurina dog" style={{width: "100%"}}/>
                </div>
            </div>
        }
        {width < 960 && 
            <div>            
                <div style={{width: "90%", padding: "5%"}}>
                    <img src={PLPHomeStatueImg} alt="myPurina dog" style={{width: "100%"}}/>
                </div>                
                <div style={{width: "90%", padding: "0 5%"}}>
                    <h3 style={{lineHeight: "39px", fontWeight: "400", fontSize: "26px"}}>Earn 10 Points for Every $1 You Spend on Purchases with myPurina!</h3>
                    <p style={{fontSize: "14px", lineHeight: "25px", fontWeight: "300", marginBottom: "30px"}}>Download the myPurina app to earn exclusive rewards, FREE samples and more! Sign up now and create a pet profile to earn 400 points!</p>
                    <a href={downloadUrl} className={classes.DownloadButton} onClick={(event) => trackDownload(event)}>Download</a>
                </div>
            </div>
        }
        </div>
    )
}

export default windowDimensions()(MyPurina)