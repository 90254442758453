import classes from './Banner.module.scss'

const Banner = () => {
    return (
        <div className={classes.Banner}>
            Thanksgiving's Top Dog Contest
        </div>
    )
}

export default Banner