import axios from './axiosInstance';

export const postEvent = async(device_uuid, event, user = null, redirect = null) => {
    let headers = {
        'Content-Type': 'application/json',
        'x-device-uuid': `${device_uuid}`,
    }

    if(user) {
        headers['Authorization'] = 'Bearer ' + user.token
    }

    await axios.post(`/event`, event, {
        headers: headers
        }).then((res) => {
            if (res && res.data) {
                console.log('Event tracked', res.data);
                if(redirect) {
                    window.open(redirect.url, redirect.target?? '_blank');
                }
            }
        }).catch((err) => {
            console.error('Error: ', err);
        }
    );
}

export const trackClickThrough = (device_uuid, event, section, url) => {
    event.preventDefault(); 
    
    postEvent(device_uuid, {
        category : "user_actions",
        subcategory : section,
        action : "click_through",
        label : url,
        client_id : parseInt(process.env.REACT_APP_CLIENT_ID),
        campaign_id : parseInt(process.env.REACT_APP_CAMPAIGN_ID)
        }, null, {url : url, target : "_blank"}
    );        
}