import PurinaLogo from '../../assets/images/purina-logo.png';
import { slide as Menu } from 'react-burger-menu'
import classes from './TopNavigation.module.scss'
import windowDimensions from 'react-window-dimensions';
import { trackClickThrough } from '../../utils/helpers';
import { useCookies } from 'react-cookie';

var burgerMenuStyles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '38px',
    height: '30px',
    right: '36px',
    top: '40px'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  },
  menuItem: {
    color: "white"
  }

}

const TopNavigation = ({ width, height }) => {
  const [cookies, setCookie] = useCookies(['A8DUUID']);

  const showSettings = (event) => {
    event.preventDefault();
  }

  return (<div className={classes.mainContainer}>
    <div style={{ position: "relative" }}>
      <a href="https://www.purina.com" onClick={(event) => trackClickThrough(cookies.A8DUUID, event, 'top_navigation', "https://www.purina.com")}><img src={PurinaLogo} className={classes.logo} alt="Purina Logo" /></a>
    </div>
    <div style={{ position: "relative", right: "0px" }}>
      {width < 1060 &&
        <Menu right styles={burgerMenuStyles} pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
          <ul className={classes.menuList}>
            <li><a className={classes.menuItem} href="https://www.purina.com/rebates">Offers</a></li>
            <li><a className={classes.menuItem} href="https://www.purina.com/where-to-buy">Where To Buy</a></li>
            <li><a className={classes.menuItem} href="https://www.nestlejobs.com/nestle-purina">Careers</a></li>
            <li><a className={classes.menuItem} href="https://www.newscenter.purina.com">News</a></li>
            <li><a className={classes.menuItem} href="https://www.purina.com/contact-us">Contact Us</a></li>
            <li><a className={classes.menuItem} href="https://www.purina.com/es">Español</a></li>
          </ul>
        </Menu>
      }
      {width >= 1060 && <div className={classes.menuListInline}>
        <a className={classes.menuItem} href="https://www.purina.com/rebates">Offers</a>
        <a className={classes.menuItem} href="https://www.purina.com/where-to-buy">Where To Buy</a>
        <a className={classes.menuItem} href="https://www.nestlejobs.com/nestle-purina">Careers</a>
        <a className={classes.menuItem} href="https://www.newscenter.purina.com">News</a>
        <a className={classes.menuItem} href="https://www.purina.com/contact-us">Contact Us</a>
        <a className={classes.menuItem} href="https://www.purina.com/es">Español</a>
      </div>}
    </div>


  </div>)
}

export default windowDimensions()(TopNavigation);
