import { Accordion as BaseAccordion, AccordionItem as Item} from '@szhsin/react-accordion';
import styles from './Accordion.module.scss';
import chevronDown from './chevron-down.png';

const AccordionItem = ({ header, ...rest }) => (
    <Item
      {...rest}
      header={
        <>
          {header}
          <img className={styles.chevron} src={chevronDown} alt="Chevron Down" />
        </>
      }
      className={styles.item}
      buttonProps={{
        className: ({ isEnter }) =>
          `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`,
      }}
      contentProps={{ className: styles.itemContent }}
      panelProps={{ className: styles.itemPanel }}
    />
  );

const Accordion = (props) => {
    
    const container = 
        <div className={styles.accordion}>
        {/* `transitionTimeout` prop should be equal to the transition duration in CSS */}
        <BaseAccordion transition transitionTimeout={250}>
            {props.data.map((item, index) => {              
                // return <AccordionItem header={item.heading} initialEntered={index === 0}>
                return <AccordionItem header={item.heading}>
                    {/* <div dangerouslySetInnerHTML={createMarkup(item.answer)}/> */}
                    <div style={{paddingLeft: "30px", paddingRight: "30px", paddingBottom: "20px", lineHeight: "32px"}}>{item.body}</div>
                </AccordionItem>
            })}
        </BaseAccordion>
    </div>


    return container
}

export default Accordion;