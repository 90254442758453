import { useEffect } from "react";
import classes from "./LiveVotingTicker.module.scss";
import CaptainThumbail from "./thumbnails/LiveTicker_Captain.png";
import HoagieThumbail from "./thumbnails/LiveTicker_Hoagie.png";
import LeilaThumbail from "./thumbnails/LiveTicker_Leila.png";
import RiverThumbail from "./thumbnails/LiveTicker_River.png";
import MissMapleThumbail from "./thumbnails/LiveTicker_MissMaple.png"
import windowDimensions from 'react-window-dimensions';


const LiveVoterTicker = (props) => {
    const data = [
        {name: "Captain", thumbnail : CaptainThumbail, value: 0},
        {name: "Hoagie", thumbnail : HoagieThumbail, value: 0},
        {name: "Leila", thumbnail : LeilaThumbail, value: 0},
        {name: "Miss Mapple", thumbnail : MissMapleThumbail, value: 0 },
        {name: "River", thumbnail : RiverThumbail, value: 0}
    ]


    data.forEach((item) => {
        console.log(item)
    })
    return (
        <div className={classes.container}>
            <h3 className={classes.title}>
                Live Voting Ticker
            </h3>
            <div style={{display: "flex", flexDirection: "row", flexFlow: "wrap", justifyContent: "space-evenly", textAlign: "center", gap: "16px"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img src={CaptainThumbail} className={classes.thumbnail} alt="Finalist"/>
                    <div className={classes.name}>VOTES FOR <br/><span style={{fontSize: "21px"}}>{"Captain"}</span></div>
                    <div className={classes.score}>{"100"}</div>
                </div>

                <div style={{display: "flex", flexDirection: "column"}}>
                    <img src={HoagieThumbail} className={classes.thumbnail} alt="Finalist"/>
                    <div className={classes.name}>VOTES FOR <br/><span style={{fontSize: "21px"}}>{"Hoagie"}</span></div>
                    <div className={classes.score}>{"100"}</div>
                </div>

                <div style={{display: "flex", flexDirection: "column"}}>
                    <img src={LeilaThumbail} className={classes.thumbnail} alt="Finalist"/>
                    <div className={classes.name}>VOTES FOR <br/><span style={{fontSize: "21px"}}>{"Leila"}</span></div>
                    <div className={classes.score}>{"100"}</div>
                </div>

                <div style={{display: "flex", flexDirection: "column"}}>
                    <img src={MissMapleThumbail} className={classes.thumbnail} alt="Finalist"/>
                    <div className={classes.name}>VOTES FOR <br/><span style={{fontSize: "21px"}}>{"Miss Mapple"}</span></div>
                    <div className={classes.score}>{"100"}</div>
                </div>

                <div style={{display: "flex", flexDirection: "column"}}>
                    <img src={RiverThumbail} className={classes.thumbnail} alt="Finalist"/>
                    <div className={classes.name}>VOTES FOR <br/><span style={{fontSize: "21px"}}>{"River"}</span></div>
                    <div className={classes.score}>{"100"}</div>
                </div>
            </div>                                                
        </div>
    )
}

export default windowDimensions()(LiveVoterTicker);