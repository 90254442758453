import {useEffect, useState, Fragment} from 'react'
import classes from './Finalists.module.scss';
import windowDimensions from 'react-window-dimensions';
import { postEvent } from '../../utils/helpers';
import { useCookies } from 'react-cookie';
import PopupHero from './assets/PopUp_Logo.png';
import PopupHeroConfirmation from './assets/PopUp_Logo_Confirmation.png';
import Select from "react-dropdown-select";
import ReCAPTCHA from "react-google-recaptcha";
import { isMobile, isIOS } from 'react-device-detect';


const dogOptions = [
    {value: "0", label: "No Dogs"},
    {value: "1", label: "1 Dog"},
    {value: "2", label: "2 Dogs"},
    {value: "3", label: "3 Dogs"},
    {value: "4", label: "4 Dogs"},
    {value: "5", label: "5 Dogs"},
    {value: "6", label: "6 Dogs"},
    {value: "7", label: "7 Dogs"},
    {value: "8", label: "8 Dogs"},
    {value: "9", label: "9 Dogs"},
    {value: "10", label: "10 Dogs"}
]

const catcOptions = [
    {value: "0", label: "No Cats"},
    {value: "1", label: "1 Cat"},
    {value: "2", label: "2 Cats"},
    {value: "3", label: "3 Cats"},
    {value: "4", label: "4 Cats"},
    {value: "5", label: "5 Cats"},
    {value: "6", label: "6 Cats"},
    {value: "7", label: "7 Cats"},
    {value: "8", label: "8 Cats"},
    {value: "9", label: "9 Cats"},
    {value: "10", label: "10 Cats"}
]


const Finalist = (props) => {
    console.log(props)
    const [cookies, setCookies] = useCookies(['A8DUUID', 'A8ALRVOT']); 
    const [initialVideoPlay, setInitialVideoPlay] = useState(true);
    const [showVoteModal, setShowVoteModal] = useState(false);
    const [voteSubmitted, setVoteSubmitted] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(true);
    const [showAlreadyVotedMessage, setSHowAlreadyVotedMessage] = useState(false);
    const [humanVerified, setHumanVerified] = useState(false);

    const submitVote = () => {
        console.log('TODO: Submit vote')
        setVoteSubmitted(true);
        setCookies('A8ALRVOT', true, {
            path: '/',
            maxAge: 3600 * 24 * 90,
            maxAge: 60,
            domain: process.env.REACT_APP_COOKIE_DOMAIN
          });
    } 

    let item; 
    if(props.width < 720) {
        item = <div style={{ margin: "10px"}}>
            <div style={{backgroundColor: "#D9D9D9", width: "150px", height: "267px"}}>
                {props.data.video ? 
                <video controls allowfullscreen style={{width: "100%", height: "100%", marginLeft: "auto", marginRight: "auto", position: "relative", top: "50%", transform: "translateY(-50%)"}} poster={props.data.picture} onPlay={(event) => {
                    // var videoList = document.getElementsByTagName("video");
                    
                    // for(let video of videoList){
                    //     if(event.target.poster !== video.poster){
                    //         video.pause();
                    //     }
                    // }

                    if (isMobile && !isIOS) {
                        // videoList.map((item)=> console.log(item));     
                        if (typeof(event.target.webkitEnterFullscreen) != "undefined") {
                            // This is for Android Stock.
                            event.target.webkitEnterFullscreen();
                        } else if (typeof(event.target.webkitRequestFullscreen)  != "undefined") {
                            // This is for Chrome.
                            event.target.webkitRequestFullscreen();
                        } else if (typeof(event.target.mozRequestFullScreen)  != "undefined") {
                            event.target.mozRequestFullScreen();
                        }
                    }

                    postEvent(cookies.A8DUUID, {
                        category: "user_actions",
                        subcategory : "home_page",
                        action : "finalist_video_play",
                        label : props.data.name,
                        client_id : process.env.REACT_APP_CLIENT_ID,
                        campaign_id : process.env.REACT_APP_CAMPAIGN_ID
                    });
                    
                }} onEnded={(event) => {
                    if (document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement ||
                    document.msFullscreenElement) {
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                          } else if (document.mozCancelFullScreen) {
                            document.mozCancelFullScreen();
                          } else if (document.webkitExitFullscreen) {
                            document.webkitExitFullscreen();
                          } else if (document.msExitFullscreen) {
                            document.msExitFullscreen();
                          }
                        }
                }}> 
                    <source src={props.data.video} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video> : 
                <img style={{width: "100%", height: "100%", objectFit: "cover"}} alt="Finalist" src={props.data.picture}/>
            }
            </div>
            <div style={{display: "flex", lineHeight: "20px", flexDirection: "column", fontFamily: "Antenna", textAlign: "center", marginTop: "10px", maxWidth: "150px"}}>
                <span style={{fontSize: "13px"}}><strong>{props.data.name}</strong></span>            
                <span style={{fontSize: "11px"}}>{props.data.handle}</span>            
                <span style={{fontSize: "9px", lineHeight: "14px", fontStyle: "italic", height: "60px"}}>{props.data.description}</span>          
                <div style={{textAlign: "center", marginTop: "10px", marginBottom: "10px"}}>
                {props.enableVote && <button style={{backgroundColor: "#2B2B2B", color: "white", padding: "8px 8px", width: "80px", borderRadius: "100px", fontSize: "12px"}} onClick={(event) => {cookies.A8ALRVOT ? setSHowAlreadyVotedMessage(true) : setShowVoteModal(true)}}>Vote</button>}
                </div>  
            </div>
            
            
        </div>
    } else {
        item = <div style={{ margin: "60px"}}>
            <div style={{backgroundColor: "#D9D9D9", width: "452px", height: "804px"}}>
            {props.data.video ? 
                <video controls style={{width: "100%", height: "100%", marginLeft: "auto", marginRight: "auto", position: "relative", top: "50%", transform: "translateY(-50%)"}} poster={props.data.picture} onPlay={(event) => {                    
                    // var videoList = document.getElementsByTagName("video");
                    
                    // for(let video of videoList){
                    //     if(event.target.poster !== video.poster){
                    //         video.pause();
                    //     }
                    // }

                    postEvent(cookies.A8DUUID, {
                        category: "user_actions",
                        subcategory : "home_page",
                        action : "finalist_video_play",
                        label : props.data.name,
                        client_id : process.env.REACT_APP_CLIENT_ID,
                        campaign_id : process.env.REACT_APP_CAMPAIGN_ID
                    });      
                    
                    if (isMobile && !isIOS) {
                        // videoList.map((item)=> console.log(item));     
                        if (typeof(event.target.webkitEnterFullscreen) != "undefined") {
                            // This is for Android Stock.
                            event.target.webkitEnterFullscreen();
                        } else if (typeof(event.target.webkitRequestFullscreen)  != "undefined") {
                            // This is for Chrome.
                            event.target.webkitRequestFullscreen();
                        } else if (typeof(event.target.mozRequestFullScreen)  != "undefined") {
                            event.target.mozRequestFullScreen();
                        }
                    }

                }} onEnded={(event)=> {
                    if (document.fullscreenElement ||
                        document.webkitFullscreenElement ||
                        document.mozFullScreenElement ||
                        document.msFullscreenElement) {
                            if (document.exitFullscreen) {
                                document.exitFullscreen();
                            } else if (document.mozCancelFullScreen) {
                                document.mozCancelFullScreen();
                            } else if (document.webkitExitFullscreen) {
                                document.webkitExitFullscreen();
                            } else if (document.msExitFullscreen) {
                                document.msExitFullscreen();
                            }
                        }
                    }}> 
                    <source src={props.data.video} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video> : 
                <img style={{width: "100%", height: "100%", objectFit: "cover"}} alt="Finalist" src={props.data.picture}/>}
            </div>
            <div style={{display: "flex", lineHeight: "28px", flexDirection: "column", fontFamily: "Antenna", textAlign: "center", marginTop: "40px", maxWidth: "452px"}}>
                <span style={{fontSize: "18px"}}><strong>{props.data.name}</strong></span>            
                <span style={{fontSize: "18px"}}>{props.data.handle}</span>            
                <span style={{fontSize: "16px", fontStyle: "italic", minHeight: "70px"}}>{props.data.description}</span>            
            </div>
            <div style={{textAlign: "center", marginTop: "20px", marginBottom: "20px"}}>
                {props.enableVote && <button style={{backgroundColor: "#2B2B2B", color: "white", padding: "8px 8px", width: "80px", borderRadius: "100px", fontSize: "12px"}} onClick={(event) => cookies.A8ALRVOT ? setSHowAlreadyVotedMessage(true) : setShowVoteModal(true)}>Vote</button>}
            </div>
        </div>
    }

    let voteModal;
    if(props.width > 1440) {
        voteModal = <div className={classes.voteModal}>
        <div>
            <img src={!voteSubmitted ? PopupHero : PopupHeroConfirmation} alt={"Pop up hero"} style={{width: "100%"}}/>
            <button style={{position: "absolute", top: "20px", right: "25px", color: "white", fontSize: "30px", border: "none", background: "none"}} onClick={() => setShowVoteModal(false)}>X</button>
        </div>
        
        {!voteSubmitted && <form style={{paddingBottom: "35px", paddingLeft: "108px", paddingRight: "108px", marginTop: "-5px", border: "#cccccc 1px solid", fontFamily: "Inter", color: "#2B2B2B", fontWeight: "300"}} onSubmit={
            (event) => {
                event.preventDefault();
                if(humanVerified) {
                    submitVote();
                }
            }
        }>
            <h2 style={{}}>To cast your vote for {props.data.name} complete the form below:  </h2>
            <p style={{fontSize: "14px", textAlign: "left"}}>All fields are required unless marked as optional. One vote per person. </p>
            <p style={{fontSize: "14px", textAlign: "left", color: "#FF0000"}}>*Required Field</p>
            <div style={{display: "flex", justifyContent: "space-between", gap: "16px",  marginBottom: "20px", textAlign: "left"}}>
                <div style={{width: "45%"}}>
                    <label for="first_name">First Name <span className={classes.required}>*</span></label><br />
                    <input type="text" required placeholder="First Name" style={{}}></input>
                </div>
                <div style={{width: "50%"}}>
                    <label for="last_name">Last Name <span className={classes.required}>*</span></label><br/>
                    <input type="text" required placeholder="Last Name" style={{}}></input>
                </div>
            </div>
            <div style={{marginBottom: "20px"}}>
                <label for="email">Email <span className={classes.required}>*</span></label>
                <input type="email" required placeholder="Email"></input>
            </div>
            <label for="myPets">My Pets <span className={classes.required}>*</span></label>            
            <div style={{display: "flex", justifyContent: "start", gap: "16px",  marginBottom: "30px", marginTop: "10px"}}>                
                <div style={{width: "30%"}}>
                    <Select 
                        placeholder='Number of Dogs'
                        options={dogOptions}
                        labelField="label"
                        valueField='value'
                        className={classes.myPetsSelect}
                        style={{border: "1px solid #B6BED2", paddingLeft: "10px"}}
                    />  
                </div>
                <div style={{width: "30%"}}>
                    <Select 
                        placeholder='Number of Cats'
                        options={catcOptions}
                        labelField="label"
                        valueField='value'
                        className={classes.myPetsSelect}
                        style={{border: "1px solid #B6BED2", paddingLeft: "10px"}}
                    />
                </div>
                <input type="checkbox" /><span style={{paddingTop: "15px"}}>Neither</span>
            </div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-evenly", marginBottom: "20px", fontSize: "14px"}}>
                <div  style={{marginBottom: "20px", flexDirection:"row"}}>
                    <input type="checkbox" />
                    <span for="optIn1" style={{marginLeft: "7px"}}>Yes, I agree to receiving email and other marketing communications from Purina and its <a href="">brands.</a>  I certify that I am over 18 years old and I have read and agree with the <a href="">Terms and Conditions.</a></span>
                </div>
                <div>
                    <input type="checkbox" required/>
                    <span for="optIn2" style={{marginLeft: "7px"}}>By clicking Vote you acknowledge you have read our <a href="">Privacy Policy</a>, <a href="">Notice at Collection</a>, and <a href="">Notice of Financial Incentive/Loyalty Program. </a></span>
                </div>            
            
            </div>
            <p style={{fontSize: "14px"}}>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply. </p>
            <div style={{display: "flex", justifyContent: "center", marginTop: "20px", marginBottom: "20px"}}>
                <ReCAPTCHA
                            sitekey="6LfKrXwqAAAAAEEe_HGFXMFXgyQ71u49WISxBjeK"
                            onChange={()=>setHumanVerified(true)}
                        />
            </div>
            <div>
                <button style={{opacity: humanVerified ? "1.0" : "0.5", backgroundColor: "#2B2B2B", color: "white", padding: "10px", border: "none", borderRadius: "100px", marginTop: "15px", width: "220px"}}>Vote</button>
            </div>
        </form>}
        
        {voteSubmitted && 
            <div style={{padding: "20px", paddingTop: "10px", fontSize: "28px", lineHeight: "36px", textAlign: "center"}}>            
                <p>Thanks for voting for <br/>Thanksgiving’s Top Dog! </p>
                <div style={{textAlign: "center", marginTop: "15px", marginBottom: "20px"}}>
                    <button style={{backgroundColor: "#2B2B2B", color: "white", padding: "10px", border: "none", borderRadius: "100px", width: "220px"}} onClick={()=>setShowVoteModal(false)}>Close</button>
                </div>                            
            </div>
        }
        
           

    </div>
    } else {
        voteModal = <div style={{position: "fixed", textAlign: "left", width: "90vw", height: voteSubmitted ? "auto" : "90vh", zIndex: "1000", backgroundColor: "white", top: "15px", maxWidth: "720px", boxShadow: "0px 4px 60px 0px #00000040", overflowY: voteSubmitted ? "hidden" : "scroll"}}>
        <div>
            <img src={!voteSubmitted ? PopupHero : PopupHeroConfirmation} alt={"Pop up hero"} style={{width: "100%"}}/>
            <button style={{position: "absolute", top: "20px", right: "15px", color: "white", fontSize: "30px", border: "none", background: "none"}} onClick={() => setShowVoteModal(false)}>X</button>
        </div>
        
        {!voteSubmitted && 
            <form style={{padding: "5%", paddingTop: "0px", marginTop: "0px", fontFamily: "Inter", color: "#2B2B2B", fontWeight: "300", width: "90%", height: "100%"}} onSubmit={
                (event) => {
                    event.preventDefault();
                    if(humanVerified) {
                        submitVote();
                    }                    
                }
            }>
                <h2 style={{fontSize: "24px", lineHeight: "32px"}}>To cast your vote for {props.data.name} <br/>complete the form below:  </h2>
                <p style={{fontSize: "14px"}}>All fields are required unless marked as optional. One vote per person. </p>
                <p style={{fontSize: "12px", color: "#FF0000"}}>*Required Field</p>
                <div style={{display: "flex", justifyContent: "space-between", gap: "16px",  marginBottom: "20px"}}>
                    <div style={{width: "98%"}}>
                        <label for="first_name">First Name <span className={classes.required}>*</span></label><br />
                        <input type="text" required placeholder="First Name" style={{}}></input>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", gap: "16px",  marginBottom: "20px"}}>                
                    <div style={{width: "98%"}}>
                        <label for="last_name">Last Name <span className={classes.required}>*</span></label><br/>
                        <input type="text" required placeholder="Last Name" style={{}}></input>
                    </div>
                </div>
                <div style={{marginBottom: "20px", width: "98%"}}>
                    <label for="email">Email <span className={classes.required}>*</span></label>
                    <input type="email" required placeholder="Email"></input>
                </div>
                <label for="myPets">My Pets <span className={classes.required}>*</span></label>            
                <div style={{display: "flex", flexDirection: "row", justifyContent: "start", gap: "16px",  marginBottom: "10px", marginTop: "10px"}}>                
                        <Select 
                            placeholder='Number of Dogs'
                            options={dogOptions}
                            labelField="label"
                            valueField='value'
                            className={classes.myPetsSelect}
                            style={{border: "1px solid #B6BED2", paddingLeft: "10px", minWidth: "125px", width: "98%"}}
                        />  

                        <Select 
                            placeholder='Number of Cats'
                            options={catcOptions}
                            labelField="label"
                            valueField='value'
                            className={classes.myPetsSelect}
                            style={{border: "1px solid #B6BED2", paddingLeft: "10px", minWidth: "125px", width: "98%"}}
                        />
                </div>
                <div>
                    <div style={{display: "flex", paddingBottom: "20px", marginBottom: "20px", borderBottom: "1px solid #B6BED2"}}>
                        <input type="checkbox" /><span style={{marginLeft: "7px", fontSize: "14px"}}>Neither</span>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-evenly", marginBottom: "20px", fontSize: "12px", lineHeight: "18px"}}>
                    <div  style={{marginBottom: "20px", display: "flex"}}>
                        <input type="checkbox"/>
                        <span for="optIn1" style={{marginLeft: "7px"}}>Yes, I agree to receiving email and other marketing communications from Purina and its <a href="">brands.</a>  I certify that I am over 18 years old and I have read and agree with the <a href="">Terms and Conditions.</a></span>
                    </div>
                    <div style={{display: "flex"}}>
                        <input type="checkbox" required/>
                        <span for="optIn2" style={{marginLeft: "7px"}}>By clicking Vote you acknowledge you have read our <a href="">Privacy Policy</a>, <a href="">Notice at Collection</a>, and <a href="">Notice of Financial Incentive/Loyalty Program. </a></span>
                    </div>    
                    <p style={{fontSize: "12px"}}>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply. </p>
                    <div style={{display: "flex", justifyContent: "center", marginTop: "20px", marginBottom: "20px"}}>
                        <ReCAPTCHA
                            sitekey="6LfKrXwqAAAAAEEe_HGFXMFXgyQ71u49WISxBjeK"
                            onChange={()=>setHumanVerified(true)}                        />   
                    </div>                         

                    <div style={{textAlign: "center", marginTop: "15px", marginBottom: "20px"}}>
                        <button style={{opacity: humanVerified ? "1.0" : "0.5", backgroundColor: "#2B2B2B", color: "white", padding: "10px", border: "none", borderRadius: "100px", width: "220px"}}>Vote</button>
                    </div>            
                </div>

            </form>
        }

        {voteSubmitted && 
            <div style={{zIndex: "1000", padding: "20px", paddingTop: "10px", fontSize: "22px", lineHeight: "32px", textAlign: "center"}}>            
                <p>Thanks for voting for Thanksgiving’s Top Dog! </p>
                <div style={{textAlign: "center", marginTop: "15px", marginBottom: "20px"}}>
                    <button style={{backgroundColor: "#2B2B2B", color: "white", padding: "10px", border: "none", borderRadius: "100px", width: "220px"}} onClick={()=>setShowVoteModal(false)}>Close</button>
                </div>                            
            </div>
        }   

    </div>
    }

    const alreadyVotedModal =  
        <div className={classes.alreadyVotedModal}>
            <div>
                <img src={PopupHeroConfirmation} alt={"Pop up hero"} style={{width: "100%"}}/>
                <button style={{position: "absolute", top: "20px", right: "15px", color: "white", fontSize: "30px", border: "none", background: "none"}} onClick={() => setSHowAlreadyVotedMessage(false)}>X</button>
            </div>
            <div style={{zIndex: "1000", padding: "20px", paddingTop: "10px", fontSize: "22px", lineHeight: "32px", textAlign: "center"}}>            
                <p>You’ve already submitted your vote! Check back to see who’s in the lead! </p>
                <div style={{textAlign: "center", marginTop: "15px", marginBottom: "20px"}}>
                    <button style={{backgroundColor: "#2B2B2B", color: "white", padding: "10px", border: "none", borderRadius: "100px", width: "220px"}} onClick={()=>setSHowAlreadyVotedMessage(false)}>Close</button>
                </div>                            
            </div>
        </div>
    return <Fragment>
        {item}
        {showVoteModal && voteModal}
        {showAlreadyVotedMessage && alreadyVotedModal}
    </Fragment>
}

const Finalists = (props) => {    
    const [cookies, setCookies] = useCookies(['A8DUUID', 'A8SHUARR']);

    const topFive = [
        { picture : "https://cdn.actv8me.com/da7d6760-646c-11ed-895b-37f2d48aaa22/assets/2024/11/2728", video: null, name: "River", handle: "@sinhsh01", url: "https://www.instagram.com/p/DBcXAZVRZ6I/", description: "River is a social cuddle bug Aussalier who loves fall walks as much as his mom does." },
        { picture : "https://cdn.actv8me.com/da7d6760-646c-11ed-895b-37f2d48aaa22/assets/2024/11/2211", video: null, name: "Maple", handle: "@marvelousmissmaple_gsp", url: "https://www.instagram.com/p/DBeeM38JCCg/", description: "Maple is a one-year-old German Shorthaired Pointer residing in Virginia Beach." },
        { picture : "https://cdn.actv8me.com/da7d6760-646c-11ed-895b-37f2d48aaa22/assets/2024/11/430", video: "https://cdn.actv8me.com/da7d6760-646c-11ed-895b-37f2d48aaa22/assets/2024/11/17309471580.mp4", name: "Leila", handle: "@lifewithmollyandleila", url: "https://www.instagram.com/p/DBoSXcYpY7n/", description: "Leila is a sporting dog in the Midwest who spreads smiles and love to all people by visiting libraries, schools, etc." },
        { picture : "https://cdn.actv8me.com/da7d6760-646c-11ed-895b-37f2d48aaa22/assets/2024/11/1139", video: "https://cdn.actv8me.com/da7d6760-646c-11ed-895b-37f2d48aaa22/assets/2024/11/17309409330.mp4", name: "Captain", handle: "@ralmillsmac", url: "https://www.instagram.com/reel/DBsBRkWtkDI/?igsh=cWN2MHhzbThnazY1", description: "Captain is a Golden Retriever puppy with a heart as big as his love for sticks." },
        { picture : "https://cdn.actv8me.com/da7d6760-646c-11ed-895b-37f2d48aaa22/assets/2024/11/3350", video: null, name: "Hoagie", handle: "@hoagieheadquarters", url: "https://www.instagram.com/p/DBXzaqwO8Xp/", description: "Hoagie is a Bernedoodle puppy beginning his training to eventually become a therapy dog and loves stealing socks." },
    ]

    // declare the function 
    const shuffle = (array) => { 
        for (let i = array.length - 1; i > 0; i--) { 
        const j = Math.floor(Math.random() * (i + 1)); 
        [array[i], array[j]] = [array[j], array[i]]; 
        } 
        return array; 
    }; 

    const [shuffledArray, setShuffledArray] = useState(topFive);

    useEffect(() => {
        console.log(props.phase)
        if(!cookies.A8SHUARR) {
            setShuffledArray(shuffle(topFive))
            console.log('shuffling...');
            setCookies('A8SHUARR', true, {
                path: '/',
                maxAge: 300,
                domain: process.env.REACT_APP_COOKIE_DOMAIN
              });                        
        } 
        
    }, [])

    return <Fragment>        
        <div style={{paddingTop: "20px", paddingTop: "10px", textAlign: "center", backgroundColor: "#EDF0F880"}}>
            {props.phase === 3 && <h2 className={classes.title}>Thanksgiving’s Top Dog Finalists</h2>}
            {props.phase === 4 && <h2 className={classes.title}>Thanksgiving’s Top Dog Contest Finalists</h2>}
            {props.phase === 4 && <p style={{paddingLeft: "20px", paddingRight: "20px"}}>Check out the finalists below and then cast your vote for Thanksgiving’s Top Dog! One vote per person. </p>}
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly"}}> 
                {shuffledArray.map((finalist, index) => {
                    return <Finalist data={finalist} width={props.width} key={index} enableVote={props.phase === 4}/>
                })}
            </div>
        </div>

    </Fragment>
}

export default windowDimensions()(Finalists);