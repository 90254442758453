import TikTokIcn from './icon-tiktok.png'
import InstagramIcn from './icon-instagram.png'
import classes from './SocialBanner.module.scss'
import windowDimensions from 'react-window-dimensions'
import { trackClickThrough } from '../../utils/helpers';
import { useCookies } from 'react-cookie';

const SocialBanner = ({width, height}) => {
    const [cookies, setCookie] = useCookies(['A8DUUID']);

    return (<>
        {width > 620 && 
        <div className={classes.SocialBanner}>
            <span>#ThanksgivingsTopDogContest</span>
            <span style={{marginLeft: "20px", position: "relative", top: "5px"}}><a href="https://www.tiktok.com/discover/thanksgiving-top-dog-contest" onClick={(event) => trackClickThrough(cookies.A8DUUID, event, 'social_banner', 'https://www.tiktok.com/discover/thanksgiving-top-dog-contest')}><img src={TikTokIcn} alt="TikTok Icon"/></a></span>
            <span style={{marginLeft: "20px", position: "relative", top: "5px"}}><a href="https://www.instagram.com/explore/search/keyword/?q=%23thanksgivingstopdogcontest" onClick={(event) => trackClickThrough(cookies.A8DUUID, event, 'social_banner', "https://www.instagram.com/explore/search/keyword/?q=%23thanksgivingstopdogcontest")}><img src={InstagramIcn} alt="Instagram Icon"/></a></span>
        </div>                
        }
        {width <= 620 && 
        <>
            <div className={classes.SocialBanner}>
            <span style={{marginLeft: "20px", position: "relative", top: "5px"}}><a href="https://www.tiktok.com/discover/thanksgiving-top-dog-contest" onClick={(event) => trackClickThrough(cookies.A8DUUID, event, 'social_banner', 'https://www.tiktok.com/discover/thanksgiving-top-dog-contest')}><img src={TikTokIcn} alt="TikTok Icon"/></a></span>
            <span style={{marginLeft: "20px", position: "relative", top: "5px"}}><a href="https://www.instagram.com/explore/search/keyword/?q=%23thanksgivingstopdogcontest" onClick={(event) => trackClickThrough(cookies.A8DUUID, event, 'social_banner', "https://www.instagram.com/explore/search/keyword/?q=%23thanksgivingstopdogcontest")}><img src={InstagramIcn} alt="Instagram Icon"/></a></span>
                <span>#ThanksgivingsTopDogContest</span>
            </div>
        </>
        } 
    </>)
} 

export default windowDimensions()(SocialBanner);