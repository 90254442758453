import './App.css';
import { useEffect, useState } from 'react';
import axios from './utils/axiosInstance';
import {v4 as uuidv4} from 'uuid';
import * as rdd from 'react-device-detect';
import { useCookies } from 'react-cookie';
import TopNavigation from './components/TopNavigation/TopNavigation';
import Banner from './components/Banner/Banner';
import Header from './components/Header/Header';
import SocialBanner from './components/SocialBanner/SocialBanner';
import Instructions from './components/Instructions/Instructions';
import Rules from './components/Rules/Rules';
import FrequentlyAskedQuestions from './components/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import MyPurina from './components/MyPurina/MyPurina';
import Footer from './components/Footer/Footer';
import { postEvent } from './utils/helpers';
import Finalists from './components/Finalists/Finalists';
import { TailSpin } from 'react-loader-spinner'
import LiveVoterTicker from './components/LiveVotingTicker/LiveVotingTicker';

const App = () => {
  const [cookies, setCookie] = useCookies(['A8DUUID', 'A8CFVLH']);
  const [device, setDevice] = useState(null);
  const [phase, setPhase] = useState(1);
  const [loading, setLoading] = useState(true);

  function registerDevice(eventData) {    

    const uuid = cookies.A8DUUID?? uuidv4();
    const deviceDetect = rdd.deviceDetect();
    const payload = {
      a8GeoIp: cookies.A8CFVLH,
      uuid: uuid,
      isMobile: deviceDetect.isMobile,
      isIphone: rdd.isIOS,
      os_name: deviceDetect.os,
      os_version: deviceDetect.osVersion,
      manufacturer: deviceDetect.vendor,
      model: deviceDetect.model,
    }
    console.log(payload)
    axios.post('/device', payload, {
      'x-device-uuid': cookies.A8DUUID
    }).then((response) => {
      if (response.status === 200 || response.status === 201) {
        setDevice(response.data);
        if (!cookies.A8DUUID) {
          console.log(uuid);
          setCookie('A8DUUID', uuid, {
            path: '/',
            maxAge: 3600 * 24 * 90,
            domain: process.env.REACT_APP_COOKIE_DOMAIN
          });
        }
        if(eventData){
          postEvent(response.data.uuid, eventData);
        }
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {

    registerDevice({
      category: "user_actions",
      subcategory : "home_page",
      action : "page_view",
      label : window.location.href,
      client_id : parseInt(process.env.REACT_APP_CLIENT_ID),
      campaign_id : parseInt(process.env.REACT_APP_CAMPAIGN_ID)
    });

    const now = new Date();
    const phaseTwoStartDate = new Date('2024-11-02T00:00:00');
    const phaseThreeStartDate = new Date('2024-11-08T00:00:00');
    const phaseFourStartDate = new Date('2024-11-28T00:00:00');

    const searchParams = new URLSearchParams(window.location.search);

    if((searchParams.has('phase') && parseInt(searchParams.get('phase')) === 2) || now >= phaseTwoStartDate) { setPhase(2)}     
    if((searchParams.has('phase') && parseInt(searchParams.get('phase')) === 3) || now >= phaseThreeStartDate) { setPhase(3);} 
    if((searchParams.has('phase') && parseInt(searchParams.get('phase')) === 4) || now >= phaseFourStartDate) { setPhase(4);}

    if((searchParams.has('s') && parseInt(searchParams.get('s')) === 3)) {
      window.location.assign('https://www.purina.com/mypurina-app?af_xp=qr&source_caller=ui&pid=QR_code&medium=tv&[…]2024_TV&af_ad=IDC_2024_TV&c=2024%20IDC%20myPurina%20TV%20Spot')
      return;
    } else {
      setLoading(false);
    }
    
  }, [])


  return (
    <div id="outer-container" style={{minWidth: "365px", maxWidth: "1920px", marginLeft: "auto", marginRight: "auto"}}>
      {loading &&  <main id="page-wrap">
        <div>
          <TailSpin
          visible={true}
          height="80"
          width="80"
          color="red"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{display: "grid", justifyContent: "center", marginTop: "45%"}}
          wrapperClass=""
          />
        </div>
        </main>}
      {!loading && <main id="page-wrap">
        <TopNavigation />
        <Banner />
        <Header phase={phase}/>
        {phase !== 4 && <SocialBanner/>}
        {phase !== 4 && <Instructions />}
        {(phase === 3 || phase === 4) && <Finalists phase={phase}/>}
        { phase === 4 && <LiveVoterTicker />}
        <Rules />
        <FrequentlyAskedQuestions />
        <MyPurina />
        <Footer />
      </main>}

    </div>
  )
}

export default App;
