import Accordion from '../shared/Accordion/Accordion'
import classes from './FrequentlyAskedQuestions.module.scss';

const data = [
    {
        heading : "How do I submit an entry for Thanksgiving’s Top Dog?",
        body: "Submit a photo or video to TikTok or Instagram using #ThanksgivingsTopDogContest between the dates of October 15, 2024 and November 1, 2024. See official rules and contest details above."
    },
    {
        heading : "When is the 2024 Thanksgiving’s Top Dog entry period?",
        body: "You can enter your dog to be Thanksgiving’s Top Dog between October 15, 2024 and November 1, 2024. See official rules and contest details for more information."
    },
    {
        heading : "What are the rules for Thanksgiving’s Top Dog?",
        body: "See the Official Rules section above for more information."
    },
    {
        heading : "Who can submit to be Thanksgiving’s Top Dog?",
        body: "Legal residents of the 50 United States and District of Columbia who are 18 years or older (19 if resident of AL or NE)."
    },
    {
        heading : "Can I submit more than one dog?",
        body: "No, only one entry is permitted per person."
    },
    {
        heading : "Are videos required?",
        body: "You must submit a video OR photo to be entered into the contest."
    },
    {
        heading : "Are photos required?",
        body: "You must submit a photo OR video to be entered into the contest."
    },
    {
        heading : "Can I use a professional photo or video?",
        body: "You may not use photos or videos taken by professionals."
    },
    {
        heading : "Who should be in the photo/video?",
        body: "Your dog should be the main focus of the photo or video! You may submit a photo or video of yourself with your dog. Please do not submit photos or videos featuring multiple dogs/pets."
    },
    {
        heading : "Can my photos or video have logos or trademarks showing?",
        body: "Please avoid prominently featuring any third-party trademarks, logos, artwork or music with your submission. Submissions may not violate copyright or trademark rights."
    },
    {
        heading : "What format should my video be in?",
        body: "All videos must be in mp4 format and be 5MB or less."
    },
    {
        heading : "How long should my video be?",
        body: "Videos should be no longer than 15 seconds max."
    },
    {
        heading : "Who picks the five finalists?",
        body: "A diverse panel of judges selected by the sponsor will evaluate all eligible entries to determine the five finalists."
    },
    {
        heading : "Who picks the winner?",
        body: "America will choose Thanksgiving’s Top Dog! National Dog Show viewers will be directed to the voting site to cast their votes during the show."
    },
    {
        heading : "What does the winner get?",
        body: "The winner will receive a one (1)-year supply of Purina Pro Plan dog food, awarded in the form of twelve (12) copuons with a maximum value of $94.99 each; and a $500.00 Chewy gift card."
    },
    {
        heading : "Do finalists win anything if they are not the top vote-earner?",
        body: "No."
    },
    {
        heading : "How and when will finalists be contacted?",
        body: "Finalists will be contacted on or around November 5, 2024, via DM (direct message) to the profile used for submitting their contest entry. Finalists are required to respond within 48 hours of DM to acknowledge that they have been chosen as a finalist."
    },
    {
        heading : "How and when will the winner be contacted?",
        body: "The winner will be contacted via email no later than December 3, 2024, with confirmation that they have been voted Thanksgiving’s Top Dog winner."
    },
    {
        heading : "How do I vote?",
        body: "Visit ThanksgivingsTopDog.com during the National Dog Show broadcast on November 28, 2024, at noon on NBC to view the five finalists and cast your vote!"
    },
    {
        heading : "Can I vote more than once?",
        body: "No, you cannot vote more than once."
    },    
    {
        heading : "Can I vote for more than one dog?",
        body: "No, you cannot vote for more than one dog."
    },        

]

const FrequentlyAskedQuestions = () => {
    return (
        <div className={classes.FrequentlyAskedQuestions}>
            <h2 className={classes.FrequentlyAskedQuestionsTitle}>Thanksgiving's Top Dog Contest FAQs</h2>
            <Accordion data={data}/>
        </div>

    )
}

export default FrequentlyAskedQuestions